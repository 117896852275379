import React from 'react';
import { Container, Box, Heading, Text, Center, Image, Flex, Spacer } from '@chakra-ui/react';
import { aboutUsMainPage } from '../../Constant/constant';
import "../ServiceView/ServiceView.css";
import sampleImage from "../../assets/welding.jpeg";
import construction from "../../assets/Construction-Maintenance1.jpg"
import construction2 from "../../assets/Civil-Construction-Projects-890x664.jpg"
import welding1 from "../../assets/weldingandfabrication1.jpg"
function AboutUs(props) {
    return (
    <>
                <Center>
          
  <Box boxSize='sm' width={"100%"} maxW={"1000px"} margin={"100px auto" } padding='4'  color='black'  height={"100%"}
   // Column layout on small screens, row layout on medium screens and above
  >
  <Center>
  <Heading fontSize={"25px"} padding={"14px"}>MEGALA INDUSTRIES</Heading>
  </Center>
  <Box>
  <Center>
  <Text fontSize='20px' color={"GrayText"}>
    {aboutUsMainPage}
</Text>
</Center>
</Box>
<div className='card-container'>
    <div className='aboutus-Card'>
    <Image

borderRadius='full'
boxSize='200px'
src={welding1}
alt='Dan Abramov'
justifyContent= 'center'

/>

    </div>
    <div className='aboutus-Card'>
    <Image

borderRadius='full'
boxSize='200px'
src={construction}
alt='Dan Abramov'
justifyContent= 'center'

/>

    </div>
    <div className='aboutus-Card'>
    <Image

borderRadius='full'
boxSize='200px'
src={sampleImage}
alt='Dan Abramov'
justifyContent= 'center'

/>

    </div>
    <div className='aboutus-Card'>
    <Image

borderRadius='full'
boxSize='200px'
src={construction2}
alt='Dan Abramov'
justifyContent= 'center'

/>

    </div>
</div>



</Box>
</Center>
 {/* <Box boxSize='sm' width={"100%"} >
    <Center>
  <Image
   boxSize='30vw'
   objectFit='cover'
  src='https://www.cdc.gov/niosh/topics/welding/images/welding-default.jpg?_=20551' alt='Dan Abramov' />
  </Center>
</Box></>  */}

</>
    );
}

export default AboutUs;