import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import CropImage from '../CropImage/CropImage';
import ImageAspectViewer from '../ImageAspectViewer/ImageAspectViewer';
import steps1 from "../../assets/20190216_125439.jpg"
import landScapeImage1 from "../../assets/20181002_113102.jpg"
import landScapeGate from "../../assets/20190625_120940.jpg"
import potraitSteps from "../../assets/20190216_125439.jpg"

const CarouselComponent = () => {

  let w = window.innerWidth;
  let h = window.innerHeight;

  console.log(w,h,"kkkkk");

    let imageArray = [
      { 
        name : "Step 1",
        src : "https://images.unsplash.com/photo-1526573059328-179b147e1b42?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3505&q=80"
      },
       
        { 
            name : "Step 1",
            src : landScapeImage1
          },
          
          { 
            name : "Step 1",
            src : landScapeGate
          }
    ];

    let imageArray2 = [
      {
        name : "",
        src : potraitSteps
      }
    ]
  return (
    <div style={{margin:"20px"}} >
     
    
    <Carousel  centerMode={true} centerSlidePercentage={90} autoPlay={true} infiniteLoop={true} interval={5000} autoPlay={true}
      interval={3000} // adjust speed as needed
      infiniteLoop={true}
      showStatus={false}
      showThumbs={false}
      emulateTouch={true} // for mobile support
      swipeable={true} // for mobile support
      dynamicHeight={true} // responsive height
       >
           { w > h ?
            imageArray.map((eachImage)=>{
               return (
                <div>
                    <img src={eachImage.src} style={{ width:"100%", height: "70vh"}}/>
                </div>
               )
            }) : 
            imageArray.map((eachImage)=>{
              return (
               <div>
                   <img src={eachImage.src} style={{ width:"100%", height: "70vh"}}/>
               </div>
              )
           })

          }
            </Carousel>
            {/* <h> hhhh{w+""+h}</h> */}
            </div>
    
  );
};

export default CarouselComponent;