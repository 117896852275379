import React from 'react'
import useFetchImages from '../../hooks/useFetchImages'
import ProductCard from '../ProductCard/ProductCard';
import useResponsiveGrid from '../../hooks/useResponsiveGrid';
import './style.css'
import { Image } from "@chakra-ui/image"
export default function BrowseProducts() {
    const url = "https://api.thecatapi.com/v1/images/search?limit=10";
    let {images, isLoading, error } = useFetchImages(url);
    let gridValue = useResponsiveGrid();
    console.log(gridValue)
    let className = gridValue ? `grid-container-${gridValue} container` :  `grid-container container`
    if (isLoading) {
        return <div>Loading...</div>;
      }
    
      if (error) {
        return <div>Error: {error.message}</div>;
      }
    
      return (
        <div className={className}>
          {/* {images.map((image,idx) => (
            <ProductCard
               url={image.url}
               key={idx}
            />
          ))} */}
          {images.map((image,idx) => (
            <Image
               boxSize="80%"
               margin={"auto"}
              //  height="fit-content"
               src={image.url}
               
            />
          ))}
        </div>
      );
}
