import React from 'react';
import './Footer.css'; 
import { Heading } from '@chakra-ui/react'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="contact-details">
          <Heading>Contact Us</Heading>
          <p>25, Reliance Mall Opp. Road </p>
          <p>Phone: 98427-70754</p>
          <p>Email: megaupvcwindows@gmail.com</p>
        </div>
        <div className="map-embed">
          <h4>Find Us Here</h4>
          <iframe
            title="Google Maps Location"
            src = "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1597.2511206997306!2d77.70508616401659!3d11.336293092832953!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1727811391788!5m2!1sen!2sin"
            width="100%"
            height="200"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
