import React, { useEffect, useState } from 'react';
import getImageAspectRatio from '../../utils/getImageAspectRatio';
import './CarouselView.css'; 

function ImageAspectViewer({ imageUrl, text }) {
  const [aspectRatio, setAspectRatio] = useState(null);
  const [styleObject, setStyleObject] = useState({width:"100%",height:"80vh",margin:"auto", objectFit:"contain"});

  return (
    
       
       ( <div style={styleObject}>
      <img src={imageUrl} alt="Image" />
      <div className="text-overlay">
            {/* <h2>{text}</h2> */}
      </div>
    </div>)
    
    
  );
}

export default ImageAspectViewer;
