// Context.js
import React, { createContext, useContext } from 'react';

const MyContext = createContext();

export const MyProvider = ({ children }) => {
  const basename = '/myapp'; // Your context value

  return (
    <MyContext.Provider value={{ basename }}>
      {children}
    </MyContext.Provider>
  );
};

export const useMyContext = () => {
  return useContext(MyContext);
};