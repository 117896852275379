import React, { useEffect } from 'react';
import { useState } from 'react';
import CarouselComponent from '../../components/Carousel/MainCarousel';
import ContactDetailsPopup from '../../components/ContactDetails/ContactDetailsPopup';
import AboutUs from '../../components/AboutUsComponent/AboutUs';
import ServiceView from '../../components/ServiceView/ServiceView';
import { Button,Text, Heading, Center } from '@chakra-ui/react';
import { aboutUsMainPage } from '../../Constant/constant';
import FloatingWhatsAppButton from '../../components/FloatingWhatsAppButton/FloatingWhatsAppbutton';
import FloatingCallButton from '../../components/FloatingCallButton/FloatingCallButton';
import Footer from '../../components/Footer/Footer';

function MainPage(props) {

    const [showPopup, setShowPopup] = useState(false);
    const [count, setCount] = useState(0);
    const contactInfo = {
        name: 'John Doe',
        email: 'johndoe@example.com',
        phone: '555-555-5555',
      };
      const togglePopup = () => {
        setShowPopup(!showPopup);
      };

      useEffect(()=>{
        // if(count < 1){
            // setCount(1);
            // setInterval(()=>{
                setShowPopup(true);
            // },5000)
            
        // }
      },[])
    return (
        <div>
            <CarouselComponent/>
            <div >
            <AboutUs/>
              </div>
              {/* <Heading>Services</Heading> */}
              <Center>
  <Heading fontSize={"30px"} padding={"0px 0px 50px"}>Services</Heading>
  </Center>
              
            <ServiceView/>
            <FloatingWhatsAppButton/>
            <FloatingCallButton/>
            <Footer/>
            
            
      {/* {showPopup && (
        <ContactDetailsPopup
          contactInfo={contactInfo}
          onClose={togglePopup}
        />
      )} */}

        </div>
    );
}

export default MainPage;