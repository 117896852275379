import logo from './logo.svg';
import './App.css';
import LandingPage  from './pages/ProductsPage';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Routes
} from "react-router-dom";
import Navbar from './components/Navbar/Navbar';
import { MyProvider } from './context/Context';
import MainPage from './pages/MainPage/MainPage';
import ProductsPage from './pages/ProductsPage';
import ContactComponent from './components/ContactComponent/ContactComponent';
import { ChakraProvider } from '@chakra-ui/react';

function App() {


  return (
    // <Routes>
    //   <Route exact path="/" component={LandingPage} />
    //   {/* Define other routes for different pages */}
    // </Routes>
    <ChakraProvider>
    <Navbar/>
    <Routes>
            <Route path="/" element={<MainPage/>}></Route>
            <Route path="/product" element={<ProductsPage/>}></Route>
            <Route path="/contact" element={<ContactComponent/>}></Route>
            <Route path="/order" element={<div>order</div>}></Route>
    </Routes>
    </ChakraProvider>
    
    
  );
}

export default App;
