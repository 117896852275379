import React from 'react';

function ContactComponent(props) {
    return (
        <div>
            <div>
                <h1>
                    Name
                </h1>
                <p>
                    Murugesan P
                </p>
                <h1>
                    Contact Us
                </h1>
                <p>
                    +91 9842770754
                </p>
                <h1>
                    Address
                </h1>
                <p>
                    Thindal
                </p>
            </div>
            <div></div>
        </div>
    );
}

export default ContactComponent;