import React from 'react';
import './FloatingCallButton.css';
import callIcon from "../../assets/icons8-call-100.png";

const FloatingCallButton = () => {
  const phoneNumber = "9842770754"; // Replace with the actual phone number

  const handleClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <div className="floating-call-button" onClick={handleClick}>
      <img src={callIcon} alt="Call" />
    </div>
  );
};

export default FloatingCallButton;
