import React from 'react';
import ServiceCard from '../ServiceCard/ServiceCard';
import './ServiceView.css';
import { Grid } from '@chakra-ui/react';
import shed from "../../assets/2-1-1.jpg"
import gate from "../../assets/Gate-Design-10-jpg.webp"
import ssRailing from "../../assets/cable-deck-railing-880x495-100920.jpg"
function ServiceView(props) {

    let serviceArray = [
        {
            image : shed,
            heading : "Factory Buildings",
            text : "Optimize your industrial space with our robust factory sheds, designed for durability and efficiency. Built with high-quality materials, our sheds provide a reliable and secure environment for your operations."
        },
        {
            image : "https://images.unsplash.com/photo-1526573059328-179b147e1b42?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3505&q=80",
            heading : "Spiral stairs",
            text : "Transform your space with our exquisite spiral stairs, designed to combine sophistication with functionality. Crafted from premium materials, our spiral stairs create a stunning focal point that enhances both modern and classic interiors."
        },
        {
            image : gate,
            heading : "Gate",
            text : "Our custom gates blend exceptional craftsmanship with modern design, creating a stunning entrance that enhances both security and style."
        },
        {
            image : ssRailing,
            heading : "Stainless Steel",
            text : "The stainless deck railing by Megala Industries. Stainless creates an open, modern look that makes the outdoor area feel spacious."
        }
    ]
    return (
        <div className='card-container'>

            {/* <Grid templateColumns='repeat(5, 1fr)' gap={6} width={"100%"}> */}
           {

         serviceArray.map((eachService)=>{
             return  <ServiceCard {...eachService}/> 
         })
           }
            {/* </Grid> */}
            
        </div>
    );
}

export default ServiceView;