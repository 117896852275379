import React from 'react'
import "./navbar.css"
import useResponsiveGrid from '../../hooks/useResponsiveGrid';
import { Link } from 'react-router-dom';
import compLogo from '../../assets/compLogo2.png'
import { Link as Link2, Box } from "@chakra-ui/react";

export default function Navbar() {

    let navContent = [
    //   {
    //   location : "/",
    //   value : 'Home'
    // }
    // ,
    // {
    //   location : "/product",
    //   value : 'Products'
    // },
    // {
    //   location : "/contact",
    //   value : 'Contact'
    // }
  ];
    console.log(navContent);
    let gridSize = useResponsiveGrid();
  
    if(gridSize === 3 || gridSize >= 4){
          return <MainNavBar
          navContent={navContent}
          />
    }

    if(gridSize <= 2){
         return <SideNavBar
          navContent={navContent}
         />
    }
}

function MainNavBar({navContent}) {

     return (
         <div className='nav-container'>
            <div className='nav-logo'>
              <img src={compLogo} alt="My Image" ></img>
            </div>
            <div className='nav-content'>
            { navContent && 
            navContent.map((eachValue)=>{
              return < div className="navbarLink">

             <Link to={eachValue?.location}>{eachValue?.value}</Link> 
              </div>
                
            })
           }
           <Box p={5}>
        <Link2 href="tel:+919842770754" color="teal.500" fontSize="lg">
          Call Us: +91 9842770754
        </Link2>
      </Box>

            </div>
       </div>
     )
}

function SideNavBar({navContent}){

  const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  return (
    <div className='side-nav-container'>
      <div className='nav-logo' style={containerStyle}>
              <img src={compLogo} alt="My Image"  style={{width:"80%", flex: 1}}></img>
            </div>

      {/* <div className='side-nav-content'>
      { navContent && 
            navContent.map((eachValue)=>{
              return   <Link to={eachValue?.location}>{eachValue?.value}</Link> 
            })
           }
            </div> */}
      {/* <div className='side-nav-bottom'>

      </div> */}

    </div>
  )
}







