import React, { useState, useEffect } from 'react';
 const useResponsiveGrid = () => {
  const [numColumns, setNumColumns] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1200) {
        setNumColumns(3);
      } else if (window.innerWidth >= 768) {
        setNumColumns(3);
      } else if (window.innerWidth >= 560) {
        setNumColumns(2);
      }else{
        setNumColumns(1);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return numColumns;
};

export default useResponsiveGrid;