import React from 'react';
import './FloatingWhatsAppButton.css';
import whatsAppImage from "../../assets/icons8-whatsapp-240.png";

const FloatingWhatsAppButton = () => {
  const message = "Hello, I have a question!";
  const phoneNumber = "9842770754"; 

  const handleClick = () => {
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <div className="floating-whatsapp-button" onClick={handleClick}>
      <img src={whatsAppImage} alt="WhatsApp" />
    </div>
  );
};

export default FloatingWhatsAppButton;
