import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import CarouselComponent from '../components/Carousel/Carousel'
import BrowseProducts from '../components/BrowseProducts/BrowseProducts'

export default function ProductsPage() {
  return (
    <div >
        
        {/* <CarouselComponent/> */}
        
        <BrowseProducts/>
    </div>
  )
}
